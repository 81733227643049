.footer-box{
    background-color: #323233;
    color: white;
}

.quick-links{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.svn-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.svn-logo-bw-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-box{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.end-strip{
    background-color: black;
    color: white;
    padding-bottom: 1vh;
}

.end-strip-item{
    text-align: center;
    vertical-align: middle;
    padding-top: 1vh;
}

.footer-links{
    display: block;
}

.footer-link{
    text-decoration: none;
    color: white;
}

.footer-link-text{
    margin-bottom: 1.25vh;
}

.footer-link:hover{
    color: rgb(123, 135, 238);
    text-decoration: none;
}

.svn-logo-box{
    display: block;
}

.logo-bw{
    height: 20vh;
}

.contact-info{
    display: block;
}

.end-strip{
    display: flex;
    justify-content: center;
}

.dev-line{
    display: inline;
}

.dev-name:hover{
    color: rgb(255, 244, 141);
}
