@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
}
.heading2 {
  font-family: 'Abel', sans-serif;
  font-size: 40px;
}
.heading3 {
  font-family: 'Abel', sans-serif;
  font-size: 20px;  
}
.bigappbar {
  display: inline-flex;
}
.smallappbar {
  display: none;
}
@media only screen and (max-width: 1026px) {
  .bigappbar {
    display: none;
  }
  .smallappbas {
    display: inline-flex;
  }
}
