@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dongle:wght@300&display=swap);
/* html::-webkit-scrollbar{
  width:0px
} */

html,body
{
    background-color: lightgray;
}
.heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
}
.heading2 {
  font-family: 'Abel', sans-serif;
  font-size: 40px;
}
.heading3 {
  font-family: 'Abel', sans-serif;
  font-size: 20px;  
}
.bigappbar {
  display: inline-flex;
}
.smallappbar {
  display: none;
}
@media only screen and (max-width: 1026px) {
  .bigappbar {
    display: none;
  }
  .smallappbas {
    display: inline-flex;
  }
}

.footer-box{
    background-color: #323233;
    color: white;
}

.quick-links{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.svn-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.svn-logo-bw-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-box{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
}

.end-strip{
    background-color: black;
    color: white;
    padding-bottom: 1vh;
}

.end-strip-item{
    text-align: center;
    vertical-align: middle;
    padding-top: 1vh;
}

.footer-links{
    display: block;
}

.footer-link{
    text-decoration: none;
    color: white;
}

.footer-link-text{
    margin-bottom: 1.25vh;
}

.footer-link:hover{
    color: rgb(123, 135, 238);
    text-decoration: none;
}

.svn-logo-box{
    display: block;
}

.logo-bw{
    height: 20vh;
}

.contact-info{
    display: block;
}

.end-strip{
    display: flex;
    justify-content: center;
}

.dev-line{
    display: inline;
}

.dev-name:hover{
    color: rgb(255, 244, 141);
}

.importers-wrapper{
    background-color: #FFFCD9;
}

.center-div-box{
    display: flex;
    align-items: center;
    justify-content: center;
}

.africa-map{
    height: 55vh;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.importers-text{
    color: #a11f1f;
    font-size: 3vh;
    font-weight: 600;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.text-36{
    font-size: 4.5vh;
    font-weight: 800;
}

.text-34{
    font-size: 4vh;
    font-weight: 700;
}

@media screen and (max-width: 575px ){
    .africa-map{
        margin-top: 4vh;
        margin-bottom: 0;
        width: 100vw;
        height: auto;
    }
    .importers-text{
        margin-top: 5vh;
        margin-bottom: 5vh;
        text-align: center;
    }
}
.gallery-box{
    background-color: white;
}
.nut-comic-image{
    height: 40vh;
}

.comic-image-box{
    background-color:#e9dcdc;
    height: 44vh;
    width: 44vh;
    transition: all .2s ease-in-out;
    border-radius: 10px;
    margin-bottom: 20vh;
    margin-top: 20vh;
}

div.comic-image-box:hover{
    Transform:scale(1.1);
}

@media screen and (max-width: 575px ){
    .comic-image-box{
        margin-top: 6vh;
        margin-bottom: 6vh;
    }
}
.products-page{
    background-color: rgb(255, 255, 255);
}

.heading-productpage{
    display: flex;
    justify-content: center;
    padding-top: 8vh;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 5vh;
}

.heading-box{
    height: 25vh;
    color: rgba(161, 31, 31, 255);
}

.line{
    width: 80%;
    background-color: rgba(161, 31, 31, 255);
    margin: auto;
    height: 2px;
}

.product-selected-box{
    background-color: rgba(255, 252, 217, 255);
    margin-left: 2vw;
    margin-right: 2vw;
}

.selected-details{
    display: flex;
    align-items: center;
    height: 45vh;
}

.attribute-points{
    font-size: 2.5vh;
    margin-top: 1.5vh;
    display: flex;
    justify-content: center;
}

.close-row-desktop{
    text-align: right;
    margin-bottom: 0;
}

.close-row-mobile{
    text-align: right;
    margin-bottom: 0;
    display: none;
}

.close-link{
    margin-right: 2vh;
    font-size: 2vh;
    margin-bottom: 0;
    padding-top: 1vh;
    font-weight: 800;
    color: red;
    cursor: pointer;
}

@media screen and (max-width: 575px ){
    .attribute-box{
        margin-left: 3vw;
    }
    .selected-details{
        display: flex;
        align-items: flex-start;
        margin-top: 2vh;
        height: 40vh;
    }
}

@media screen and (max-width: 1000px){
    .close-row-desktop{
        display: none;
    }

    .close-row-mobile{
        display: inline;
    }
}
.card-box{
    width: 18vw;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3vh;
    margin-top: 4vh;
    padding-bottom: 2.5vh;
    border-radius: 10px;
    margin-bottom: 5vh;
    border: 1px solid #BFBFBF;
    background-color: white;
    box-shadow: 6px 6px 5px #aaaaaa;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

div.card-box:hover{
    Transform:scale(1.1);
}

.thumbnail{
    width: 16vw;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 2vh;
}

.product-details{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
}

.product-name{
    font-size: 4vh;
    font-family: 'Dongle', sans-serif;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
}

@media screen and (max-width: 1199px ){
    .card-box{
        width: 18vw;
        padding-top: 3vh;
        margin-top: 3vh;
        padding-bottom: 2vh;
    }
    .thumbnail{
        width: 16vw;
    }
}

@media screen and (max-width: 991px ){
    .card-box{
        width: 26vw;
        padding-top: 4vh;
        margin-top: 5vh;
        padding-bottom: 2.5vh;
    }
    .thumbnail{
        width: 24vw;
    }
}

@media screen and (max-width: 767px ){
    .card-box{
        width: 30vw;
    }
    .thumbnail{
        width: 26vw;
        margin-left: 2vw;
    }
}

@media screen and (max-width: 575px ){
    .card-box{
        width: 50vw;
        padding-top: 4vh;
        margin-top: 6vh;
        padding-bottom: 3.5vh;
    }
    .thumbnail{
        width: 44vw;
        margin-left: 3vw;
        margin-right: 3vw;
    }
}
